<template>
<span :key="fileName">
    <i v-if="bsIconClass === null" class="o-icon" ></i>
    <i v-if="bsIconClass !== null" :class="bsIconClass" ></i>
</span>
</template>

<script setup>
import { getIconUrlFromFileName, getIconUrlFromExtension, getExtensionFromFileName, getBsIcon } from 'o365.modules.fileIcons.ts';
import { computed } from 'vue';
const props = defineProps({
    fileName: {
        required: false,
        type: String
    },
    extension: {
        required: false,
        type: String
    },
    size: {
        required: false,
        type: String,
        default: '1rem'
    }
});

const vExtension = computed(() => props.extension ?? getExtensionFromFileName(props.fileName));

const bsIconClass = computed(() => getBsIcon(vExtension.value));

const url = computed(() => {
    return getIconUrlFromExtension(vExtension.value)
});
const iconurl = computed(() => `url('${url.value}')`);
const iconsize = computed(() => `${props.size}`);
</script>
<style scoped>
    .o-icon:before{
        content: "";
        display: inline-block;
        height:v-bind(iconsize);
        width:v-bind(iconsize);
        background-size: v-bind(iconsize);
        background-image: v-bind(iconurl);
        background-repeat: no-repeat;
     
    }
</style>